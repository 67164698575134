<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader
            :title="headercontent.title"
            :addlink="headercontent.addlink"
          />
        </v-col>
        <v-col md="12" v-if="!pageLoading">
          <h3 class="filtertitle">Filter {{ headercontent.title }}</h3>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row
              v-if="
                allVideoSubCategory &&
                allVideoSubCategory.results &&
                allVideoSubCategory.results.list
              "
            >
              <v-col cols="3">
                <v-text-field
                  v-model="title"
                  outlined
                  label="Title"
                  class="mr-btm-minus"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-select
                  :items="allVideoSubCategory.results.list"
                  item-value="categoryHandle"
                  item-text="categoryHandle"
                  label="Category"
                  outlined
                ></v-select>
              </v-col>

              <v-col cols="3">
                <v-select
                  :items="[
                    {
                      text: 'Active',
                      value: true,
                    },
                    {
                      text: 'Inactive',
                      value: false,
                    },
                  ]"
                  item-text="text"
                  item-value="value"
                  label="Status"
                  outlined
                ></v-select>
              </v-col>

              <v-col cols="3" class="submitbtn">
                <v-btn class="submitbutton btn-dark" @click="filterAction">
                  Filter Category
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>

        <v-col md="12">
          <v-simple-table class="table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">S/N</th>
                  <th class="text-left">Title</th>
                  <th>Category</th>
                  <th>Status</th>
                  <th class="text-right">Action</th>
                </tr>
              </thead>
              <tbody v-if="!pageLoading">
                <tr
                  v-for="(item, index) in allVideoSubCategory.results.list"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.title }}</td>
                  <td>{{ item.categoryHandle }}</td>
                  <td>
                    <span
                      class="status"
                      :style="{
                        'background-color':
                          item.status === true ? 'green' : 'red',
                      }"
                      >{{ item.status === true ? "Active" : "Inactive" }}</span
                    >
                  </td>
                  <td class="text-right">
                    <router-link
                      :to="`/dashboard/videoSubcategory/add/${item.slug}`"
                    >
                      <v-icon class="icons-action"> mdi-pencil </v-icon>
                    </router-link>
                    <v-icon
                      class="icons-action"
                      @click="() => deleteSubcategoryAct(item._id)"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "VideoCategory",
  components: { PageHeader },
  data: () => ({
    headercontent: {
      title: "Video Course Listing",
      addlink: "/dashboard/videoSubcategory/add",
    },
    title: "",
    adsposition: "",
    valid: true,
    limit: 100,
    skip: 1,
    id: undefined,
    pageLoading: true,
    loading: false,
  }),
  methods: {
    ...mapActions(["fetchVideoSubCategory", "deleteSubcategory"]),
    async filterAction() {
      await this.fetchVideoSubCategory({
        limit: this.limit,
        skip: this.skip,
        title: this.title,
        categoryHandle: this.categoryHandle,
        status: this.status,
      });
    },
    nextPage(data) {
      this.fetchVideoSubCategory({
        limit: this.limit,
        skip: data,
        categoryType: "video",
      });
    },
    prevPage(data) {
      this.fetchVideoSubCategory({
        limit: this.limit,
        skip: data,
        categoryType: "video",
      });
    },
    async deleteSubcategoryAct(id) {
      if (confirm("Are you sure you want to delete this course?")) {
        this.loading = true;
        this.id = id;
        await this.deleteSubcategory(id);
        await this.fetchVideoSubCategory({
          limit: this.limit,
          skip: this.skip,
          categoryType: "video",
        });
        this.loading = false;
        this.id = undefined;
      }
    },
  },
  computed: {
    ...mapGetters(["allVideoSubCategory", "allLoadingCurd"]),
  },
  async created() {
    await this.fetchVideoSubCategory({
      limit: this.limit,
      skip: this.skip,
      categoryType: "video",
    });

    this.pageLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.filtertitle {
  margin-bottom: 10px;
}
.title {
  font-size: 1.875rem !important;
  font-weight: bold;
  margin: 10px 0 0 10px;
}

.btn-dark {
  background: $tagcolor !important;
  color: $white;
  margin-top: -10px;
}
.table {
  box-shadow: 0px -1px 5px 2px rgba(0, 0, 0, 0.1);

  thead {
    border-bottom: 2px solid $menucolor;
    background: $tagcolor;
  }
  th {
    font-size: $normal_lfont !important;
    color: $white !important;
  }
}
.submitbtn {
  margin-top: 10px;
  .submitbutton {
    padding: 28px 20px;
  }
}
.status {
  display: inline-block;
  padding: 5px 20px;
  color: white;
  width: 100px;
  text-align: center;
  border-radius: 15px;
  font-weight: bold;
}
</style>
